@import '../styles/variables.scss';

*{
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
}

a{
  text-decoration: none;
  color: $white;
}

ul li{
  list-style-type: none;
}


h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
button,
footer {
    margin: 0;
    padding: 0;
}
