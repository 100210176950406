@import '../../../styles/variables.scss';
@import '../../../styles/common.scss';
@import '../../../styles/responsive.scss';
@import '../../../styles/reset.scss';


.footer{
  @include d-flex;

  @include mobile{
    @include d-block;
  }

  @include tablet{
    @include d-block;
  }

  .banner1{
    @include relative;
    width: 30%;
    height: auto;
    cursor: pointer;

    @include mobile{
      width: 100%;
    }

    @include tablet{
      width: 100%;
    }

    .overlay1{
      @include absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background: $black;
      opacity: .3;
      transition: opacity .4s ease;

      &:hover{
        opacity: .1;
      }
    }

    img{
      width: 100%;
      height: 100%;
    }
  }

  .producing{
    width: 40%;
    height: auto;
    @include d-flex{
      align-items: center;
      justify-content: center;
    }

    @include mobile{
      width: 100%;
      padding: 15% 5%;
    }

    @include tablet{
      width: 100%;
      padding: 15% 5%;
    }

    .content{
      max-width: 60%;
      height: auto;

      @include mobile{
        max-width: 480px;
        width: 100%;
        height: auto;
      }

      @include tablet{
        max-width: 768px;
        width: 100%;
        height: auto;
      }
    }

    .title{
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 800;
      letter-spacing: 5px;
      line-height: 22px;
      padding-bottom: 9px;

      @include tablet{
        font-size: $font-size + 7px;
        padding-bottom: 1rem;
      }
    }

    .para{
      color: $text-grey;
      font-size: 12px;
      letter-spacing: -0.25px;
      line-height: 20px;

      @include tablet{
        font-size: $font-size + 5px;
        line-height: 25px;
      }
    }
  }

  .banner2{
    @include relative;
    width: 30%;
    height: auto;
    cursor: pointer;
    
    @include mobile{
      width: 100%;
      height: auto;
    }

    @include tablet{
      width: 100%;
      height: auto;
    }

    .overlay2{
      @include absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background: $black;
      opacity: .3;
      transition: opacity .4s ease;

      &:hover{
        opacity: .1;
      }
    }

    img{
      width: 100%;
      height: 100%;
    }
  }
}