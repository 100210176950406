@import '../../../styles/variables.scss';
@import '../../../styles/reset.scss';
@import '../../../styles/common.scss';
@import '../../../styles/responsive.scss';

.page1{
  @include mobile{
    max-width: 480px;
  }
}

.flex-box{
  @include d-flex;

  @include mobile{
    @include d-block;
  }

  @include tablet{
    @include d-block;
  }

  .left1{
    width: 60%;
    height: auto;

    @include mobile{
      max-width: 480px;
      width: 100%;
      height: 534px;
    }

    @include tablet{
      max-width: 768px;
      width: 100%;
      height: 488px;
    }

    .sidebar1{
      @include relative;
      background-size: auto;
      background-repeat: no-repeat;
      width: 100%;
      height: 534px;

      @include mobile{
        max-width: 480px;
        width: 100%;
        height: 534px;
        background-image: none;
      }

      @include tablet{
        max-width: 768px;
        width: 100%;
        height: 534px;
        background-image: none;
      }

      .imageDesktop1{
        width: 100%;
        height: 534px;  
        @include absolute;
        transform: scale(1);
        transition: all .4s ease;

        @include mobile{
          max-width: 480px;
          height: 534px;
        }

        @include tablet{
          max-width: 768px;
          height: 534px;
          width: 100%;
        }
      }

      .imageDesktop2 {
        width: 100%;
        height: 534px;
        @include absolute;
        transform: scale(.9);
        transition: all .4s ease;
      }
      
      .imageDesktop3 {
        width: 100%;
        height: 534px;
        @include absolute;
        transform: scale(.9);
        transition: all .4s ease;
      }

      .menu{
        @include absolute;
        width: 70%;
        z-index: 5;
        padding: 10% 5%;

        @include mobile{
          @include d-flex{
            align-items: center;
          }
          padding: 0;
          width: 100%;
        }

        @include tablet{
          padding: 10% 5%;
          width: 100%;
        }

        @include middle{
          width: 100%;
          padding: 10% 5%;
        }

        .menu-btn{
          @include d-none;

          margin: 10% 5%;
          width: 20px;
          height: 14px;
          cursor: pointer;
          transition: all .4s ease;

          @include tablet{
            @include d-none;
          }

          @include mobile{
            @include d-block;
          }
          
          &:hover{
            transform: scale(1.3);
          }
        }

        .room-m{
          @include d-none;

          @include mobile{
            @include d-block;

            margin-left: 25%;
            color: $white;
            font-size: $font-size-title - 5px;
            cursor: pointer;
            transition: all .4s ease;
          }

          &:hover{
            color: $light-grey;
          }
        }

        .list{
          @include d-flex{
            align-items: center;
            justify-content: space-evenly;
          }

          @include mobile{
            @include absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 0;
            opacity: 0;

            background-color: $white;
            transition: all .4s ease;
          }

          @include tablet{
            @include d-flex{
              align-items: center;
              justify-content: space-evenly;
            }
          }

          .room{
            font-size: $font-size-title - 10px;
            margin-bottom: 3px;

            @include mobile{
              background-image: url("../../../asset/images/icon-close.svg");
              background-repeat: no-repeat;
              background-size: cover;
              width: 15px;
              height: 15px;

              text-indent: 100%;
              white-space: nowrap;
              overflow: hidden;
              cursor: pointer;
              transition: transform .4s ease;

              &:hover {
                transform: rotate(180deg);
              }
            }

            a{
              transition: all .4s ease;
            }

            &:hover a{
              color: $light-grey;
            }

            @include tablet{
              margin-right: 2rem;

              a{
                color: $white;
                transition: all .4s ease;
              }

              a:hover{
                color: $light-grey;
              }
            }

          }

          .item{
            @include relative;

            @include mobile{
              font-size: $font-size;
            }

            a{
              cursor: pointer;

              @include mobile{
                color: $black;
                @include relative;
              }
            }

            &:after{
              content: '';
              @include absolute;
              left: 50%;
              right: 50%;
              opacity: 0;
              bottom: -3px;
              width: 0;
              height: 2px;
              background: white;
              @include mobile{
                background: $black;
              }
              transition: all .2s ease-in-out;
            }

            &:hover::after{
              left: 0;
              right: 0;
              opacity: 1;
              width: 100%;
            }
          }
        }

        .list-m {
          height: 63%;
          opacity: 1;
        }
      }

      .navigation{
        @include d-none;
        @include absolute;

        z-index: 2;
        right: 0;
        bottom: 0;
        width: 120px;
        height: 60px;

        @include tablet{
          @include d-block;
        }

        @include mobile{
          @include d-block;
        }

        .navigation-items{
          @include d-flex{
            align-items: center;
          }
          height: 60px;

          .left-arrow{
            cursor: pointer;
            width: 60px;
            height: 60px;
            background-color: $black;
            transition: all .4s ease;

            @include d-flex{
              justify-content: center;
              align-items: center;
            }

            &:hover{
              background-color: $btn-active;
            }

            &:hover > .leftNav{
              margin-right: 5px;
            }

            .leftNav{
              width: 12px;
              height: 24px;
              background-image: url("../../../asset/images/icon-angle-left.svg");
              background-repeat: no-repeat;
              transition: margin-right .4s ease;
            }
          }

          .right-arrow{
            cursor: pointer;
            width: 60px;
            height: 60px;
            background-color: $black;
            transition: all .4s ease;

            @include d-flex{
              justify-content: center;
              align-items: center;
            }

            &:hover{
              background-color: $btn-active;
            }

            &:hover > .rightNav{
              margin-left: 5px;
            }

            .rightNav{
              width: 12px;
              height: 24px;
              background-image: url("../../../asset/images/icon-angle-right.svg");
              background-repeat: no-repeat;
              transition: margin-left .4s ease;
            }
          }
        }
      }

      .overlay{
        @include absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgb(58, 58, 58), white);
        background-size: auto;
        background-repeat: no-repeat;
        opacity: 0.2;
      }
    }
  }
}

.App{
  .page1{
      @include mobile{
      @include absolute{
        z-index: auto;
      }
    }
  }

  .overlay3{
    @include d-none;

    @include mobile{
      @include d-block;
      @include absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      background-color: black;
      opacity: 0;
    }
  }
}