@import '../../../styles/variables.scss';
@import '../../../styles/common.scss';
@import '../../../styles/responsive.scss';

.container{
  @include d-flex{
    justify-content: center;
    align-items: center;
  }
  @include relative;
  width: 40%;

  @include mobile{
    max-width: 480px;
    width: 100%;
    padding: 15% 5%;
  }

  @include tablet{
    max-width: 768px;
    width: 100%;
    padding: 15% 5%;
  }

  .content{
    max-width: 80%;
    height: auto;

    @include middle{
      max-width: 80%;
      height: auto;
    }

    @include mobile{
      max-width: 480px;
      width: 100%;
    }

    @include tablet{
      max-width: 600px;
      width: 100%;
      height: auto;
    }

    .title{
      font-size: $font-size-title-mobile;
      letter-spacing: -1.67px;
      line-height: 1;
      opacity: .9;
      transition: all .4s ease;

      @include tablet{
        font-size: $font-size-title;
        transition: all .4s ease;
      }
    }

    .para{
      color: $text-grey;
      opacity: .9;
      transition: all .4s ease;

      line-height: 20px;
      letter-spacing: -0.25px;
      font-size: $font-size;
      padding-top: 22px;
      padding-bottom: 32px;

      @include tablet{
        font-size: $font-size + 5px;
        line-height: 25px;
        transition: all .4s ease;
      }
    }

    .shop-now{
      color: $black;
      text-transform: uppercase;
      letter-spacing: 10px;
      transition: color .4s ease;

      @include tablet{
        font-size: $font-size + 5px;
      }

      @include middle{
        font-size: $font-size - 2px;
      }

      &:hover{
        color: $text-grey;
      }

      &:hover + .rightArrow{
        margin-left: 40px;
        color: $text-grey;
      }
    }

    .rightArrow{
      @include d-inline-block;
      width: 40px;
      height: 12px;
      background-image: url("../../../asset/images/rightArrow.svg");
      margin-left: 23px;
      transition: margin-left .4s ease;

      @include middle{
        margin-left: 10px;
        transition: margin-left .4s ease;
      }
    }
  }

  .navigation{
    @include absolute;
    left: 0;
    bottom: 0;
    width: 160px;
    height: 80px;

    @include mobile{
      @include d-none;
    }

    @include tablet{
      @include d-none;
    }

    .navigation-items{
      @include d-flex{
        align-items: center;
      }
      height: 80px;

      .left-arrow{
        cursor: pointer;
        width: 80px;
        height: 80px;
        background-color: $black;
        transition: all .4s ease;

        @include d-flex{
          justify-content: center;
          align-items: center;
        }

        &:hover{
          background-color: $btn-active;
        }

        &:hover > .leftNav{
          margin-right: 5px;
        }

        .leftNav{
          width: 12px;
          height: 24px;
          background-image: url("../../../asset/images/icon-angle-left.svg");
          background-repeat: no-repeat;
          transition: margin-right .4s ease;
        }
      }

      .right-arrow{
        cursor: pointer;
        width: 80px;
        height: 80px;
        background-color: $black;
        transition: all .4s ease;

        @include d-flex{
          justify-content: center;
          align-items: center;
        }

        &:hover{
          background-color: $btn-active;
        }

        &:hover > .rightNav{
          margin-left: 5px;
        }

        .rightNav{
          width: 12px;
          height: 24px;
          background-image: url("../../../asset/images/icon-angle-right.svg");
          background-repeat: no-repeat;
          transition: margin-left .4s ease;
        }
      }
    }
  }
}